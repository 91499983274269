<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
						v-if="$store.state.userData"
            :src="'https://api.metav.cl/assets/img/perfiles/' + $store.state.userData.img_url"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="editUser" >
      <CIcon name="cil-user" /> {{$t('profile')}}
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> {{$t('logout')}}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  methods: {
    editUser(){
      this.$store.commit('setEditUserModal', {status: true, userData: this.$store.state.userData})
    },
    logout(){
      localStorage.removeItem('userToken')
      this.$store.commit('setIsAuthenticated', false)
      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>