<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CImg
        class="c-sidebar-brand-full"
        :src="require('@/assets/img/logometavsec.png')"
        :height="35" 
      />
      <CImg
        class="c-sidebar-brand-minimized" 
        :src="require('@/assets/img/MetaV_Isotipo.png')"
        :height="35"
        :width="35"
        style="object-fit: contain"
      />
    </CSidebarBrand>

		<CSidebarNav>
			<CSidebarNavItem
				name="Dashboard"
				to="/dashboard"
				icon="cil-speedometer"
				v-if="$store.state.user && $store.state.user.type != 2"
			/>
			<CSidebarNavItem
				:name="$t('myClients')"
				to="/users"
				icon="cil-user"
				v-if="$store.state.user && $store.state.user.type != 2 &&
							$store.state.userData && $store.state.userData.account_level != 3"
			/>
			<CSidebarNavItem
				:name="$t('myEvaluations')"
				to="/evaluations"
				icon="cil-file"

			/>
			<CSidebarNavItem
				:name="$t('myNetwork')"
				to="/networkusers"
				icon="cil-people"
				v-if="$store.state.user && $store.state.user.type != 2"
			/>
		</CSidebarNav>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
